import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@dex/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Epilogue Regular', sans-serif;
    }
    body {
        background-color: ${({ theme }) => theme.colors.background};

        img {
            height: auto;
            max-width: 100%;
        }
   }
  
   .default-button {
        color: white;
        background-origin: padding-box,border-box;
        border: none !important;
        background: #5676bb;
        background: linear-gradient(105deg, #3ebec9 0%, #5676bb 100%) !important;
    }
    .default-button.pancake-button--disabled {
        color: #FFFFFF77 !important;
    }
`

export default GlobalStyle
